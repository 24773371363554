// App theme colors only

$app-theme-primary:  #242424;
$app-theme-secondary:#323232;
$app-body-bg:        #f1f1f1;
$app-layout-bg:      #f5f5f5;

$white:#ffffff;
$black: #242424;
$yellow: #f5cd00;
$green: #009700;
$shadow:#ddd;

// App Header


$app-header-bg:                 #fff;
$app-header-color:              #323232;

$app-sub-header-bg:             #dedede;
$app-sub-header-color:          #323232;

$app-footer-bg:                 #ffffff;
$app-footer-color:              #242424;

$app-header-menu-bg:            #242424;
$app-header-menu-color:         #242424;

$app-header-menu-active-bg:     $yellow;
$app-header-menu-active-color:  #230B34;

$app-header-menu-icon-width:     22px;
$app-header-menu-icon-height:    22px;

$app-header-menu-text-size:      15px;
$app-header-menu-text-weight:    500;





// Grid Variable's


$grid-header-bg:        rgba(0, 0, 0, 0.12) !important;
$grid-header-color:     #000000b8;

$grid-data-bg:          #ffffff;
$grid-data-color:       #242424;

$grid-data-odd-bg:      #ffffff;
$grid-data-odd-color:   #200E32;

$grid-data-even-bg:     #f9f9f9;
$grid-data-even-color:  #200E32;

$grid-data-active-bg:   #f9f9f9;
$grid-data-active-color:#200E32;


$grid-header-text-size:     13.5px;
$grid-header-text-weight:   600;

$grid-data-text-size:       14px;
$grid-data-text-weight:     500;

$btn-default-bg:             $yellow;
$btn-default-color:         #242424;
$btn-default-border-color:  #fff;

$btn-primary-bg:            $yellow;
$btn-primary-color:         #242424;
$btn-primary-border-color:  #fff;

$btn-secondary-bg:          #242424;
$btn-secondary-color:       #ffffff;
$btn-secondary-border-color:#ffffff;

$btn-success-bg:            #1cff42;
$btn-success-color:         #000000;
$btn-success-border-color:  #ffffff;

$btn-warning-bg:            #ff8800;
$btn-warning-color:         #242424;
$btn-warning-border-color:  #242424;

$btn-danger-bg:             #ff0000;
$btn-danger-color:          #000000;
$btn-danger-border-color:   #ffffff;

$btn-light-bg:              #fff;
$btn-light-color:            #000;
$btn-light-border-color:     #fff;

$btn-dark-bg:               #000000;
$btn-dark-color:            #ffffff;
$btn-dark-border-color:     #fff;

$btn-info-bg:               #242424;
$btn-info-color:            #242424;
$btn-info-border-color:     #242424;

$btn-rose-bg:               #242424;
$btn-rose-color:            #242424;
$btn-rose-border-color:     #242424;


$btn-height: 46px;
$btn-width:  max-content;
$btn-border-radius:  5px;

$btn-padding: 5px 20px;

$box-shadow: 0px 1px 5px #f1f1f1; 


$input-field-size:13px;
$input-field-bg: #ffffff;
$input-field-color: #000000;
$input-field-border-radius: 0px;

$layer-back-drop:#efefef96;




 