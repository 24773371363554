//== Variables
$statusNone: green;
$statusOne: #FF2222;
$statusTwo: blue;
$statusThree: orange;
$idle:#d9d9d93d;
$sick:#b90606a3  ;
$vacation:#732173be ;
$inprogress:#f2ee1af3;
$upcoming:#444d58;
$done: #5ab226f7;
$completed:#99fd15a0;
$overdue: #fa2e0aed;
$group:#dedede;
$holiday:#f5f5f5;
$leaves:#9d6ff9cf ;
$neutral: #419100;
/*--------------Resizable Start--------------------*/

.ui-resizable {
	position: relative;
}
.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
	display: none;
}
.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0;
}
.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0;
}
.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}
.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}
.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}
.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}
.ui-draggable {
	cursor: move;
}

/*--------------Resizable End--------------------*/


//== Styling


.ts-item-event {
	border-width: 1px;
	border-style: solid;
	width: 2px;
}
.item-event-none {
    background-color: $statusNone;
    border-color: darken($statusNone, 10%);
	}
.item-event-one {
    background-color: $statusOne;
    border-color: darken($statusOne, 10%);
	}
.item-event-two {
    background-color: $statusTwo;
    border-color: lighten($statusTwo, 10%);
	}
.item-event-three {
    background-color: $statusThree;
    border-color: darken($statusThree, 10%);
	}

.ts-item {
	transition: background-color ease 0.1s;
	border-width: 1px;
	border-style: solid;
	margin-top: 3px;
	&-none {
		background-color: $idle !important;
		color: darken($idle, 40%)!important;;
		&:hover {
			background-color: darken($idle, 8%)!important;;
		 }
	 }
	&-vacation {
		background-color: $vacation !important;
		color: #fff  !important;
		&:hover {
			background-color: darken($vacation, 8%)!important;;
		}
	}
	&-leaves{
		background-color: $leaves !important;
		color: #fff  !important;
		&:hover {
			background-color: darken($leaves, 8%)!important;;
		}
	}
	&-sick {
		background-color: $sick !important;
		color: #fff  !important;
		&:hover {
			background-color: darken($sick, 8%)!important;;
		}
	}
	&-holiday {
		background-color: $holiday !important;
		color: #fff  !important;
		&:hover {
			background-color: darken($sick, 8%)!important;;
		}
	}
	&-upcoming {
		background-color: $upcoming !important;
		color:  #fff  !important;
		border: 1px solid $upcoming;
		&:hover {
			background-color: darken($upcoming, 8%)!important;;
			}
	}
	&-completed {
		background-color: $completed !important;
		color:  #fff  !important;
		border: 1px solid $completed;
		&:hover {
			background-color: darken($completed, 8%)!important;;
			}
	}
	&-inprogress {
		background-color: $inprogress !important;
		color:  #fff  !important;
		border: 1px solid $inprogress;
		&:hover {
			background-color: darken($inprogress, 8%)!important;
			}
	}
	&-done {
		background-color: $done !important;
		color:  #fff  !important;
		border: 1px solid $done;
		&:hover {
			background-color: darken($done, 8%)!important;
			}
	}
	&-overdue {
		background-color: $overdue !important;
		color:  #fff  !important;
		border: 1px solid $overdue;
		&:hover {
			background-color: darken($overdue, 8%)!important;
			}
	}
	&-neutral {
		border: 1px solid $neutral;
		box-shadow: 0px 0px 5px rgb(19, 97, 4);
	}
	&-start {
		left: 2px;
		position: absolute;
		top: 12px;
		bottom: 12px;
		border-right: 2px solid #fff;
		}
	&-end {
		right: 2px;
		position: absolute;
		top: 12px;
		bottom: 12px;
		border-left: 2px solid #fff;
	}
	&-content {
		overflow: hidden;
		white-space: nowrap;
		font-size:12px;
		padding: 10px 5px;
		position: relative;
		z-index: 1;
	}
	&.ui-draggable-dragging {
		cursor: move;
		}
	}

.realtime-info {
	display: none;
}


.ts-wrapper {
	.ts-header-1-
	 {
		background-color: #999999;
		border-color: #888888;
	}
	.d-start{
		border-left-color: #e9e9e9;
	}
	.d-end{
		border-right-color: #8888888a;
	}
}


.ts-wrapper, .ts-header-wrapper {
	position: relative;
}

.ts-table {
	width: max-content !important;
	min-width: 100vw !important;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	.group{
		background-color: $group;
		.sticky{
			background-color: $group;
			}
	}
}

//== Header section
.ts-period-container {
	float: left;
	position: relative;
	}
.ts-time-container {
	float: right;
	position: relative;
	}

.ts-button {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	}

.ts-time-button-goto {
	margin-right: .5em;
	}
.ts-time-button-today {
	margin-right: .5em;
	}

.ts-content-wrap {
	position: relative;
 }

//== Table defaults
.ts-wrapper {
	th, td {
		min-width: 50px;
		border-width: 1px;
		border-style: solid;
		border-top-color: #e9e9e9;
		border-bottom-color: #e9e9e9;
		border-left-color: #e9e9e9;
		border-right-color: #e9e9e9;
		}

	.ts-section-header {
		border-top-color: transparent;
		border-left-color: transparent;
		border-bottom-color: transparent;
		}

	.ts-date-header {
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		background: #ebebeb;
	}

	.ts-times-header-1{
		box-shadow: 0px 1px 2px #dbd5d5;
	}

	.ts-section {
		width: 260px;
		}
	}

//== Section rows
.ts-section-content {
	vertical-align: middle;
	text-align: center;
	overflow: hidden;
	}

//== Sections
.ts-section-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1;

	padding-left: 1px;
	padding-right: 1px;
	}
	.row-cntr {
		position: relative;
		overflow: hidden;
	}
	.row-gp {
		position: relative;
		overflow: hidden;
	}

//== Calendar items
.ts-item {
	position: absolute;
	min-height: 40px;
	clear: both;
}

.ts-item-c {
	position: absolute;
	min-height: 39px;
	clear: both;
}

.ts-item-icon {
	float: left;
	width: 1em;
	height: 1em;
	}



.ts-item-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ts-item-event {
	position: absolute;
	top: 1px;
	bottom: 1px;
	width: 1px;
	}


//== Utilities
.ts-clearfix {
	:before, :after {
		content: '\0020';
		display: block;
		overflow: hidden;
		visibility: hidden;
		width: 0;
		height: 0;
		}

	:after {
		clear: both;
		}

	zoom: 1;
}


//== Basic print styling to get rid of easily forgotten elements
@media print {
	.ts-current-time {
		display: none;
	}
}

//== Related
.ts-header-wrapper {
	padding: .5em;
	margin-bottom: .5em;
	}
.ts-title {
	text-align: center;
	font-size: 1.25em;
	}

.ts-item {
	background-color: $idle;
	color: white;
    border-radius: 2px;
    cursor: pointer;
}

.ts-date-header {
    padding: 2px;
	font-size: 12px;
	text-align: center;
}

.ts-times:first-child .ts-date-header {
	font-weight: bold;
	text-align: center;
	padding: 4px;
	background: #fbfbfb;
    font-size: 12px;
	border-top: transparent;
    box-shadow: 0px 1px 2px #dbd5d5;
}

.ts-current-time {
	border-left: 2px solid #f5cd00;
	position: absolute;
	height: 100%;
	z-index: 2;
	top: 0;
	bottom: 0;
	&::before{
	  content: "";
	  width: 10px;
	  height: 10px;
	  position: absolute;
	  z-index: 999999999;
	  margin: 0 -10.5px;
	  border-radius: 20px;
	  border-top: 9px solid #f5cd00;
	  border-left: 9px solid transparent;
	  border-right: 9px solid transparent;
	}
}

//== Related to the header buttons
.ts-button {
	border: 1px solid #E1E1E1;
	font-weight: bold;
	padding: .25em;

	text-decoration: none;
	color: #222222;

	height: 20px;
	line-height: 20px;

	padding-left: .5em;
	padding-right: .5em;

	&.ts-selected-button {
		cursor: default;
		background-color: #EEEEEE;
		}

	&.ts-period-button {
		&:first-child {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			}
		&:last-child {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			}
		}

	&.ts-time-button {
		background-repeat: no-repeat;
		background-position: center center;

		min-width: 20px;
		}

	&.ts-time-button-next {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
    content:'>'
		// background-image: url(images/arrow_right.png);
		}
	&.ts-time-button-prev {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
    content:'<'
		// background-image: url(images/arrow_left.png);
		}
	&.ts-time-button-goto, &.ts-time-button-today {
		border-radius: 4px;
		}
}

.sticky{
	left: 0px;
	position: sticky;
	z-index: 2;
	background-color: #fff;
}

.gp-bg{
	background-color: #e9e9e970;
}


.hover-element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }

  .hover-element:hover {
    background-color: #f00; /* Update with desired hover effect */
  }

  @mixin highlight-animation {
	transition: border-color 0.3s ease;
  }

  .conflicted-row {
	@include highlight-animation;
	border: 2px solid #f5cd00;
	font-weight: bold;
	background-color: #f5cd0008;
	&.even {
	  border-color: lighten(#f5cd00, 15%);
	}

	&.odd {
	  border-color: darken(#f5cd00, 15%);
	}
  }



