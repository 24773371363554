// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$yellowTime-primary: mat.define-palette(mat.$indigo-palette);
$yellowTime-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$yellowTime-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$yellowTime-theme: mat.define-light-theme((color: (primary: $yellowTime-primary,
        accent: $yellowTime-accent,
        warn: $yellowTime-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($yellowTime-theme);
@import '~bootstrap/dist/css/bootstrap.css';
@import './assets/css/ngx-table.scss';
@import './assets/css/variable.scss';
@import './assets/css/calendar.scss';
@import './assets/css/style2.scss';
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap');

html,
body {
  height: 100%;
  overflow: hidden !important
}

[hidden]{
  visibility: hidden !important;
  display: unset !important;
}
.toggle-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
}

input {
  outline: none !important;
}

input:focus-visible {
  outline: none !important;
}



.white-bg {
  background-color: white !important;
}

.sticky-header {
  position: sticky !important;
  top: 0;
  z-index: 3;
  background: white;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-size-14px {
  font-size: 14px !important;
}

.mins-label {
  padding: 6px;
  min-width: 70px !important;
  max-width: 70px !important;
  background-color: #2fec3520;
  border-radius: 4px;
  text-align: center;

  label {
    font-size: 13px !important;
    padding: 0px !important;
    color: $green  !important;
  }
}

.green-label {
  padding: 9px 12px;
  width: 100px !important;
  border-left: 3px solid #1cb547db;
  background-color: #2fec3520;

  label {
    font-size: 14px !important;
    padding: 0px !important;
    color: $green  !important;
  }
}

.error-banner{
  background-color: rgba(211, 35, 35, 0.0862745098);
  color: rgba(234, 15, 15, 0.8588235294);
  font-size: 13px;
  padding: 8px 5px;
}

.red-label {
  padding: 9px 12px;
  min-width: 75px;
  width: 100px !important;
  border-left: 3px solid #ea0f0fdb;
  background-color: #d3232316;

  label {
    font-size: 14px !important;
    padding: 0px !important;
    color: "red" !important;
  }
}

.yellow-label {
  padding: 9px 12px;
  min-width: 75px;
  width: 100px !important;
  border-left: 3px solid #f8d50ff8;
  background-color: rgba(211, 185, 35, 0.264);

  label {
    font-size: 14px !important;
    padding: 0px !important;
    color: grey !important;
  }
}

.edit-btn {
  padding: 5px 10px;
  min-width: 80px !important;
  background: #eaffea !important;
  border: 1px solid #e7e9ed70 !important; //#b3e0b35c
  color: $green  !important;
}

.delete-btn {
  padding: 5px 10px;
  min-width: 80px !important;
  background: rgba(255, 60, 60, 0.2) !important;
  border: 1px solid #e7e9ed70 !important; //#ffb3b347
  color: #ff0202 !important;
}

.title-header {
  font-size: 1.31rem;
  font-weight: 500;
}

.title-sub-header {
  font-size: 0.8rem;
  margin: 0px 2px;
  color: rgba(0, 0, 0, 0.54);
}

.location-poly {
  align-items: center;
  justify-content: center;
  background: $layer-back-drop;
  display: flex;
  padding: 7px;
  border-radius: 3px;
  max-width: 120px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
}

.box-loader {
  position: relative;
  top: -10px;
}


.yellow-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f7db4c+0,f9e371+52,fbeca0+100 */
  background: #f7db4c;
  /* Old browsers */
  background: -moz-linear-gradient(top,
      #f7db4c 0%,
      #f9e371 52%,
      #fbeca0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top,
      #f7db4c 0%,
      #f9e371 52%,
      #fbeca0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      #f7db4c 0%,
      #f9e371 52%,
      #fbeca0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7db4c', endColorstr='#fbeca0', GradientType=0);
  /* IE6-9 */
  width: 100%;
  height: 100%;
}

.example-spacer {
  flex: 1 1 auto;
}

.login-card {
  .mat-card {
    border-radius: 20px !important;
    box-shadow: 4px 5px 9px #d2b868 !important;
    width: 480px;
    padding: 3rem !important;
  }

  .brand {
    img {
      width: 230px;
      margin: auto;
    }
  }

  h2 {
    font-size: 22px;
    color: #f5cd00;
    text-transform: uppercase;
    font-weight: 600;
  }

  h3 {
    font-size: 15px;

    span {
      font-weight: 600;
    }
  }

  .rem-chk {
    font-size: 13px;
    color: #000;

    button {
      text-decoration: underline;
    }
  }

  .act-btn {
    button {
      border-radius: 50px;
      min-width: 150px;
      line-height: 38px;
    }
  }

  .foot {
    p {
      font-size: 13px;
      // color: red;
      margin: 0;
      font-weight: 500;
    }
  }
}

.body-padding {
  padding: 1.5rem !important;
}

.title-text {
  font-size: 21px !important;
  padding: 10px 15px !important;
}

.c-header {
  font-size: 21px !important;
}

button.btn-no-padding,
.btn-no-padding {
  padding: 0 !important;
  height: inherit;
  min-height: inherit;
  min-width: inherit;
  line-height: inherit;
  font-size: 13px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  clear: both;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $btn-primary-bg;
  color: $btn-primary-color;
  box-shadow: none;
  font-weight: normal;
  padding: $btn-padding !important;
  min-width: 110px !important;
  font-size: 15px;
}

.mat-raised-button.mat-button-disabled{
  color :#00000042!important;
}

.mat-flat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary {
  background-color: $btn-secondary-bg;
  color: $btn-secondary-color;
  box-shadow: none;
  font-weight: normal;
  padding: $btn-padding  !important;
  min-width: 110px !important;
  font-size: 15px;
}

.mat-stroked-button.mat-primary {
  color: #242424;
  border-color: #242424;
  box-shadow: none;
  font-weight: normal;
  min-width: 110px;
  font-size: 15px;
}

.mat-input-element:disabled{
    color:rgba(0, 0, 0, 0.9) !important;
}

.action-btn.mat-flat-button.mat-primary,
.action-btn.mat-raised-button.mat-primary {
  color: $btn-primary-color;
  padding: $btn-padding  !important;
  box-shadow: none;
  font-size: 15px;
  font-weight: 440;
}

.action-btn.mat-flat-button.mat-secondary,
.action-btn.mat-raised-button.mat-secondary {
  color: $btn-secondary-color;
  //padding: $btn-padding  !important;
  box-shadow: none;
  font-size: 15px;
  font-weight: 440;
}

.action-btn.mat-flat-button.mat-secondary,
.action-btn.mat-raised-button.mat-secondary {
  font-weight: 500;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

.text-grey {
  color: #7a7866;
}

.mat-form-field {
  &.pwd {
    button {
      font-size: 20px !important;
      color: #7a7866;
    }
  }
}

.mat-form-field {
  &.mat-focused {
    .mat-form-field-wrapper {}
  }

  &.is-invalid,
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      border-color: red;
    }
  }

  &.mat-form-field-disabled {
    cursor: not-allowed;

    .mat-form-field-wrapper {
      cursor: not-allowed;
    }
  }
}

.mat-chip.mat-standard-chip {
  background-color: #dfdfdf94;
  color: rgba(0, 0, 0, 0.87);
  min-height: 25px;
  font-size: 12px;
}

.mat-standard-chip {
  border-radius: 3px !important;
}

table {
  width: 100%;

  tr {
    th.mat-header-cell {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.text-primary {
  color: #323232 !important;
}

.align-center {
  align-items: center !important;
}

.ft30 {
  font-size: 30px !important;
}

.icon_dashboard {
  mask-size: cover;
  mask-image: url('./assets/images/icon_dashboard.svg');
}

.icon_emp {
  mask-size: cover;
  mask-image: url('./assets/images/icon_emp.svg');
}

.icon_cust {
  mask-size: cover;
  mask-image: url('./assets/images/icon_cust.svg');
}

.header {
  z-index: 111;

  .top-row {
    background-color: $app-header-bg;
    padding-left: 35px;

    button {
      color: $app-header-color;

      .material-icons,
      img {
        margin-right: 5px;
      }
    }
  }

  .nav-row {
    background-color: $app-sub-header-bg;
    padding: 0 2.4rem;
    height: 55px;

    a,
    button {
      font-size: 15px;
      padding: 21px 25px;
      color: $app-sub-header-color;
      font-weight: 400;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .mat-icon {
        margin-right: 6px;
      }

      .icon {
        background-color: $app-sub-header-color;
        margin-right: 6px;
        width: 22px;
        height: 22px;
      }

      .material-icons {
        margin-right: 6px;
      }

      .mat-icon.drop {
        font-size: 16px;
        width: auto;
        height: auto;
        padding-left: 5px;
      }

      &.active {
        background-color: $app-header-menu-active-bg;
        border-radius: 3px;
        color: $app-header-menu-active-color;
        font-weight: 600;
        z-index: 4;

        .icon {
          background-color: $app-header-menu-active-color;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.sub {
  min-width: 160px;

  a.nav-link {
    color: #323232;
    // padding: 0 !important;
  }

  .active {
    background-color: $app-header-menu-active-bg;
    color: $app-header-menu-active-color;
    font-weight: 600;
  }
}


.fw300 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.main-content {
  background-color: #e5e5e538;
}

.left-content {
  height: calc(100vh - 130px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(207, 207, 207);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }
}

.right-content {
  iframe {
    width: 100%;
    height: 100%;
  }
}


.order-card {
  padding: 15px !important;
  height: inherit;
  background-color: #fff;
  box-shadow: 0px -1px 5px #ddd !important;
}


.mat-card.customer-card {
  padding: 20px 25px !important;
  height: inherit;
  margin: 18px !important;

  .mat-card-title {
    font-size: 21px;
    font-weight: 500;
    padding: 0 !important;
    margin-bottom: 0px !important;
  }

  .mat-card-subtitle {
    font-size: 13px;
    margin-bottom: 8px;
  }
}


.user-info {
  font-weight: 300 !important;
  margin: 15px;

  span {
    font-weight: 500;
  }
}

.mat-form-field.mat-form-field-appearance-fill {
  &.search-bar {
    min-width: 250px;

    .mat-form-field-infix {
      padding: 16px 12px;
      border-top: 0;
    }

    .mat-form-field-flex {
      padding: 0;
      border-radius: 4px;
      background-color: #f3f3f3;
    }

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    mat-icon {
      font-size: 23px;
      position: relative;
      top: 5px;
      margin-left: 5px;
    }

    mat-icon.close {
      font-size: 21px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }

    .mat-form-field-suffix {

      .mat-flat-button {
        padding: 5.5px 20px;
        min-width: 100px;
        border-radius: 0 4px 4px 0;
      }
    }
  }
  &.search-bar-sm {
    min-width: 230px;

    .mat-form-field-infix {
      padding: 12px 0px;
      border-top: 0;
    }

    .mat-form-field-flex {
      padding: 0;
      border-radius: 4px;
      background-color: #f3f3f3;
    }

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    mat-icon {
      font-size: 23px;
      position: relative;
      top: 5px;
      margin-left: 5px;
    }

    mat-icon.close {
      font-size: 21px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }

    .mat-form-field-suffix {

      .mat-flat-button {
        padding: 5.5px 20px;
        min-width: 100px;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

.srh.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

.nw-frm {
  form {
    width: 850px;
    margin: auto;
    border: 1px solid #efefef;
    padding: 20px;
    box-shadow: 0 3px 8px #eaeaea;
    border-radius: 8px;
  }

  .mat-form-field,
  .rdo {
    width: 100%;
  }

  .action-btn {
    width: auto;
  }
}

.bk-btn.mat-icon-button {
  padding: 0;
  width: inherit;
  height: inherit;
  min-height: inherit;
  line-height: inherit;
  position: relative;
  top: -3px;
  margin-right: 4px;

  .mat-icon {
    font-size: 30px;
    width: 30px;
  }
}

.actBr {
  display: flex;

  button.mat-button-base {
    padding: 8px 12px;
    min-width: inherit;
    line-height: inherit;
    font-size: 12px;
    border-radius: 4px;
    background: #d1d1d1;
    color: #323232;

    .material-icons {
      font-size: 20px;
    }

    img {
      margin-right: 4px;
    }

    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}


.usr-lst {
  align-items: center;
  border: 1px solid #f1f1f1; //0 4px 3px #f1f1f1;
  position: relative;
  padding: 20px 12px;
  border-radius: 4px;
  transition: transform 0.2s;
  cursor: pointer;

  span.char {
    display: inline-block;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid #ddd;
    border-radius: 100px;
    text-align: center;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    margin-right: 12px;
    background-color: #efefef;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 2px;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 13px;
  }

  .last-sn {
    // display: inline-flex;
    font-size: 12px;
    position: absolute;
    right: 12px;
    top: 16px;

    .mat-icon {
      font-size: 16px;
    }
  }

  &:hover {
    background-color: #fafafa;
  }
}

.usr-cht {
  box-shadow: 0px 1px 5px #746b6b4a;
  position: relative;
  padding: 16px 12px;
  border-radius: 5px;
  height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(207, 207, 207);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }
}

.cht-msg {
  margin-bottom: 8px;

  p {
    margin: 0;
    position: relative;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    span {
      margin: 0 0 4px;
      font-size: 13px;
      background-color: #f7f7f7;
      border-radius: 10px 10px 10px 0;
      padding: 8px 12px;
      max-width: 60%;
      display: inline-block;
    }
  }

  span {
    font-size: 11px;
  }
}

.rht-msg {
  .cht-msg {
    p {
      span {
        background-color: #f5cd00;
        color: #323232;
        text-align: left;
      }
    }
  }
}

.highlight{
  background: #f5cd00 !important;
}

.highlight-o{
  background: #f55e00 !important;
}

.snd-inp {
  align-items: center;
  margin-top: 10px;

  .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 5px 10px;
    border-radius: 5px;

  }
  .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix{
    top: 0px !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    top: -3px;
    padding-left: 6px;
    width: auto;
  }

  .mat-form-field-flex .mat-form-field-infix{
    width: 100% !important;
  }

  .mat-form-field-prefix .mat-icon {
    font-size: 24px;
    position: relative;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex{
    margin-top: -0.5rem!important;
  }

  button {
    margin-top: 0px !important;
    .material-icons {
      font-size: 18px;
      transform: rotate(-35deg);
    }
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.info-link .material-icons {
  cursor: pointer;
}

.bg-white {
  background-color: $white !important;
}

.lcte {
  button {
    color: #230b34 !important;

    .mat-icon {
      color: #230b34;
    }
  }
}

button.btn-sm {
  padding: 3px 12px !important;
  line-height: inherit;
  min-height: 28px;
  min-width: inherit !important;
  height: 32px;
  font-size: 13px !important;
}

//Material Input Box Override

.mat-form-field {
  font-size: $input-field-size;
   margin-bottom: -0.2rem;
}
.mat-error{
  margin: 0px 0px 2px 2px;
  font-size: 12px;
}

.mat-form-field-outline {
  background-color: $input-field-bg;
  border-radius: $input-field-border-radius;
}

.mat-form-field-label {
  color: $input-field-color !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.9em 0 0.9em 0;
}

//Material Button Override
.submit-button {
  background-color: $btn-secondary-bg  !important;
  padding: $btn-padding;
  color: $btn-secondary-color  !important;
  box-shadow: none;
  font-weight: normal;
  min-width: 110px;
  font-size: 15px;
  font-weight: 500;
}

.save-button {
  background-color: #242424;
  color: #fff;
  box-shadow: none;
  font-weight: normal;
  min-width: 110px;
  font-size: 15px;
}

.shadow-button {
  background-color: #242424;
  color: #fff;
  box-shadow: none;
  font-weight: normal;
  min-width: 110px;
  font-size: 15px;
}

input:-webkit-autofill {
  background-color: $white  !important;
}

.step {
  width: 45px;
  height: 45px;
  font-size: 21px;
  font-weight: 500;
  color: #242424;
  background-color: #f5cd00;
  display: inline-block;
  border-radius: 100px;
  text-align: center;
  line-height: 45px;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent{
  background-color: transparent;
}

.mat-horizontal-stepper-wrapper {
  .mat-horizontal-stepper-header-container {
    .mat-step-label {
      display: none;
    }

    .mat-step-header {
      padding: 10px 15px;

      .mat-step-icon {
        width: 50px;
        height: 50px;
        font-size: 22px;
        font-weight: 600;
        color: #242424;
        background-color: rgba(0, 0, 0, 0.12);

        &.mat-step-icon-selected {
          background-color: #f5cd00;
        }

        &.mat-step-icon-state-edit {
          background-color: #f5cd00;
        }
      }
    }

    .mat-stepper-horizontal-line {
      border-top-width: 3px;
      border-top-style: dashed;
    }
  }

  .mat-horizontal-content-container {
    padding: 0 0 24px 0px;
  }
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
[dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
[dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  border-top-width: 3px !important;
  border-top-style: dashed !important;
  width: calc(50% - 26px) !important;
}

.ordr-stpr.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
  margin-bottom: 15px;
  background-color: #f1f0f0;
  border-radius: 8px;
  height: 70px;
}

.font-weight-500 {
  font-weight: 500;
}

.hf-width {
  width: 80%;

  &.mat-form-field-appearance-outline .mat-form-field-infix {
    width: auto;
  }
}

.dl-fld {
  &.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &.mat-form-field-appearance-outline .mat-form-field-infix {
    width: auto;
  }
}

.dl-slt-wdt {
  &.mat-form-field-appearance-outline {
    width: 100%;
  }
}


.tm-pkr {
  display: flex;
  width: 28%;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 7px 8px;

  input {
    width: 100%;
    border: 0;
  }

  ngx-material-timepicker-toggle {
    .ngx-material-timepicker-toggle {
      svg {
        width: 20px;
      }
    }
  }
}

.full-width {
  width: 100% !important;
}

.grey-box {
  background-color: #f1f0f0;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
}
.ft11{
  font-size: 11px;
}

.ft12 {
  font-size: 12px;
}

.ft13 {
  font-size: 13px;
}

.ft14 {
  font-size: 14px;
}

.ft18 {
  font-size: 18px;
}

.tsk-do {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 6px;
  height: 150px;
  overflow-y: auto;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      position: relative;
      padding: 5px;

      span {
        background-color: #f1f0f0;
        width: 42px;
        height: 42px;
        margin: 2px;
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 45px;
        cursor: pointer !important;

        img {
          width: 32px;
          margin: auto;
        }
      }

      .mat-icon {
        position: absolute;
        top: 0;
        right: 5px;
        color: #c8c8c8;
        font-size: 17px;

        &.checked {
          color: #2a85ff;
        }
      }
    }
  }

  &.border-0 {
    border: 0;
    padding: 0;
    margin: 15px 0 0;
    min-height: inherit;
  }
}


.tsk-do_ms {

  img {
    width: 32px;
  }
}

.tasks {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      position: relative;
      padding: 5px;
      span {
        background-color: #f1f0f0;
        width: 42px;
        height: 42px;
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 45px;
        cursor: pointer !important;
        img {
          width: 28px;
          margin: auto;
        }
      }

      .mat-icon {
        width: 32px;
        text-align: center;
        color: black;
        font-size: 25px;

        &.checked {
          color: #2a85ff;
        }
      }
    }
  }
}

button.btn-sm {
  padding: 1px 12px;
  font-size: 14px;
  min-width: 75px;
}

.i-28 {
  width: 28px !important;
  height: 28px !important;
  font-size: 28px !important;
}
.i-29 {
  width: 29px !important;
  height: 29px !important;
  font-size: 29px !important;
}
.i-32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 32px !important;
}

.i-o-21 {
  font-size: 21px !important;
  height: 35px !important;
  width: 35px !important;
}

.i-21 {
  font-size: 21px !important;
}

.i-30{
  font-size: 30px !important;
}

.i-26-g {
  font-size: 26px !important;
  color: #19161685;
  text-align: right;
}

.i-26-s {
  font-size: 29px !important;
  text-align: right;
  height: 29px !important;
  width: 29px !important;
}

.i-26-e {
  font-size: 29px !important;
  text-align: right;
  height: 29px !important;
  width: 29px !important;
}


.i-23-w {
  font-size: 21px;
  color: white;
  background-color: #242424;
}


.pl-20 {
  padding-left: 20px !important;
}



// .reorder {
//   border-radius: 6px;
//   position: relative;
//   &_header {
//     background-color: $btn-primary-bg;
//     padding: 15px 2px;
//     border-radius: 4px 4px 0px 0px;
//     min-height: 50px;
//     max-height: 50px;

//     h6 {
//       font-size: $grid-header-text-size;
//       font-weight: $grid-header-text-weight;
//       text-align: center;
//       color: $black;
//       width: 85%;
//       margin-bottom: 0px;
//       cursor: pointer;
//     }

//     img {
//       cursor: pointer;
//     }
//   }

//   &_left{
//     position:absolute;
//     height:100vh;
//   }
//   &_right{
//     position:relative;
//     height:100vh;
//   }
//   &_column {
//     box-shadow: 0px 1px 5px #ddd !important;
//     z-index: 1;
//     margin: 0px 6px;
//     background-color: white;
//     height:100vh;
//   }

//   .tsk-lst {
//     padding: 10px 5px;
//     overflow-x: auto;
//     overflow-y: auto;
//   }
// }

.desc-text {
  width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sticky-srch {
  background: white;
  padding: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.pre-ord-header {
  padding: 10px;
  background: white;
  position: static;
}

.pre-ord-list {
  max-height: 50vh;
  background: transparent;
  overflow-y: scroll;
}

.timepicker {

  .mat-toolbar.mat-accent,
  .mat-mini-fab.mat-accent {
    background-color: $app-header-bg  !important;
    color: $app-header-color  !important;
  }
}

.ngx-pagination a {
  text-decoration: unset !important;
}

.mat-drawer-container{
  z-index: 10 !important;
}

.notification {
  .notification-card {
    margin: 5px 0px;
    background: white;
    box-shadow: $box-shadow;
    padding: 10px;
  }
}
.err-fld{
  .p-s{position: relative;}
  .mat-form-field{
    .mat-form-field-wrapper{
      padding-bottom:1.7em !important;
    }
    &.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
      color: #f44336;
    }
    &.mat-form-field-appearance-outline{
      .mat-form-field-wrapper{
      padding-bottom:1.7em !important;
    }
    }
  }
  .mat-error{
    font-weight: 360;
    font-size: 11px;
    position: absolute;
    width: max-content;
    bottom: 1px;
  }
}
.ft-14{font-size: 14px !important;}.pr-2{padding-right: 16px;}
.mat-slide-toggle.mat-checked{
  .mat-slide-toggle-bar{background-color: #fbeb99;}
  .mat-slide-toggle-thumb{background-color:#f5cd00}
}

.yes-no {
    width: 32px;
    display: block;
    text-align: center;
}

.ft-13{font-size: 13px !important;}
.yes-no-sm {
  width: 35px;
  font-size: 13px;
 display: block;
  text-align: center;
}

/* NOTE: For Grid with search please use this class in mat-card over-view */
