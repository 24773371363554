
.yellow-bg{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f7db4c+0,f9e371+52,fbeca0+100 */
      background: #f7db4c; /* Old browsers */
      background: -moz-linear-gradient(top,  #f7db4c 0%, #f9e371 52%, #fbeca0 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  #f7db4c 0%,#f9e371 52%,#fbeca0 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  hsl(50, 91%, 63%) 0%,#f9e371 52%,#fbeca0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7db4c', endColorstr='#fbeca0',GradientType=0 ); /* IE6-9 */
      width: 100%;height: 100%;
  }

  .example-spacer {
    flex: 1 1 auto;
  }

  .person-icon {
    background-image: url('./../icons/person-icon.svg');
    height: 40px;
    width: 40px;
    background-size: cover;
    background-position: center;
    display:inline-block;
    margin: -4px 0px;
  }

  .ecaption{
    display: inline-block;
    margin: -7px 30px 0px 20px;
    z-index: 4;
    position: absolute;
  }

  .login-card{
    .mat-card{
      border-radius: 20px !important;
      box-shadow: 4px 5px 9px #d2b868 !important;
      width: 480px;
      padding: 3rem !important;
    }
    .brand{
      img{
        width: 230px;
        margin: auto;
      }
    }
    h2{
      font-size: 22px;
      color: #f5cd00;
      text-transform: uppercase;
      font-weight: 600;
    }
    h3{
      font-size: 15px;
      span{font-weight: 600;}
    }
    .rem-chk{
      font-size: 13px;
      color: #000;
      button{
        text-decoration: underline;
      }
    }
    .act-btn{
      button{
        border-radius: 50px;
        min-width: 150px;
        line-height: 38px;
      }
    }
    .foot{
      p{
        font-size: 13px;
        // color: red;
        margin: 0;
        font-weight: 500;
      }
    }
  }

.float-left{float: left;}.float-right{float: right;}.clearfix{clear: both;}
  
.text-right{text-align: right;}.text-left{text-align: left;}

.text-green{color: green;}.text-red{color: red;}.text-blue{color: blue;}.text-grey{color: #7a7866}

.text-primary{
    color: #323232 !important;
  }

.align-center{align-items: center !important;}

.ft30{font-size: 30px !important;}

.fw300{font-weight: 400;}.fw500{font-weight: 500;}

.icon_dashboard{
    mask-size: cover;
    mask-image: url('../images/icon_dashboard.svg');
  }
  .icon_emp{
    mask-size: cover;
    mask-image: url('../images/icon_emp.svg');
  }
  .icon_cust{
    mask-size: cover;
    mask-image: url('../images/icon_cust.svg');
  }

  .nw-frm{
    form{
      width: 850px;
      margin: auto;
      border: 1px solid #efefef;
      padding: 20px;
      box-shadow: $box-shadow;// 0 3px 8px #eaeaea;
      border-radius: 8px;
    }
    .mat-form-field, .rdo{
      width: 100%;
    }
    .action-btn{
      width: auto;
    }
  }
  
  .shadow-button {
    background-color: $btn-primary-bg;
    color: $btn-primary-color;
    box-shadow:none;
    font-weight: normal;
    min-width: 110px;
    font-size: 15px;
  }

  .submit-button {
    background-color: $btn-secondary-bg !important;
    color: $btn-secondary-color !important;
    box-shadow:none;
    font-weight: normal;
    min-width: 110px;
    font-size: 15px;
  }
  .custom-mat-menu{
    .mat-menu-content{
      min-width: 120px;
      button.mat-menu-item{
        line-height: 40px;
        height: auto;
        padding: 0 12px;
      }
    }
  }
  .custom-filter{
    &.mat-menu-panel{
      min-width: 400px;
      max-width: 400px;
      width: 400px;
      .mat-menu-content{

      }
    }

  }
  .dt-pic{
    &.mat-form-field-appearance-legacy{
      top: 7px;
      .mat-form-field-infix{padding: 0;font-size: 15px; font-weight: 500;width: auto;}
      .mat-form-field-underline{display: none;}
      .mat-form-field-prefix{font-size: 20px;}
      .mat-form-field-wrapper{padding-bottom: 0;}
      .mat-date-range-input-end-wrapper{width: 93px;}
    } 
  }
  .full-width{width: 100%;}
  .mat-dialog-title{
    margin: 0 0 15px !important;
    display: block;
    font-size: 16px;
    color: #000000;
    line-height: inherit;
  }
  .ml-2{margin-left: 16px !important;}.m-0{margin: 0 !important;}
  .mat-tab-list{
    .mat-tab-labels{
      .mat-tab-label{
        border: 0;
        &.mat-tab-label-active{
          // background-color: #323232 !important;
          // border-radius: 6px;
          // color: #fff;
          // border: 0;
          opacity: 1;
        }
      }
    }
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    border: 1px solid #ddd;
 }
 .mat-expansion-panel:not([class*=mat-elevation-z]){
     box-shadow: none !important;
 }
 .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]){
     background: transparent; 
 }
 
 .mat-expansion-panel {
     background: #fff;
 }
 
 .mat-expansion-panel-header.mat-expanded {
     height: 50px;
 } 
 .top-banner{
  background-color:#f5cd00;
  color: #230B34;
  font-size: 15px;
  padding: 12px 5px; 
 } 
 .mat-menu-panel{
  min-height: 48px !important
 }