@import './variable.scss';
$black-color: #2e2e2e;
.ngx-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 6px;
}
.ngx-container--dark {
  background-color: #50596c;
}
.ngx-table {
  color: $black-color;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin-top: -1px;
  tr:nth-child(even) {background: #fbfbfb; cursor: pointer;}
  tr:nth-child(odd) {background: #FFF; cursor: pointer;} 
}
.ngx-table td {
  border-bottom: 1px solid #f1f1f1 !important;
  padding: 12px 10px;
  height: 45px;
  font-size: 13px;
  color:#323232;
  cursor: pointer;
}
.ngx-table th {
  padding: 15px 10px;
  font-weight: 500;
  font-size: 13px;
  background-color: #efefef;
  color: #323232;
  &:first-child{
    border-radius: 6px 0 0 0;
  }
  &:last-child{
    border-radius: 0 6px 0 0;
  }
}
.ngx-table .ngx-table__sort-header th{
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.ngx-table__table-row--selected, .ngx-table__table-col--selected, .ngx-table__table-cell--selected {
  background: #e7e9ed !important;
}
.ngx-table__table-no-results {
  text-align: center;
}
.ngx-table__table-loader-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.ngx-table__body-loading:hover {
  background: 0 !important;
}
.ngx-table__table-loader {
  animation: spin 0.6s linear infinite;
  border: 1px solid #e7e9ed;
  border-radius: 50%;
  border-top-color: #50596c;
  height: 1.8rem;
  width: 1.8rem;
}
.ngx-table__table-row-context-menu {
  position: absolute;
}
.ngx-table__header-search {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff !important;
  border: 0.05rem solid #e7e9ed;
  border-radius:6px;
  color: $black-color;
  display: block;
  font-size: 0.75rem;
  height: 1.47rem;
  line-height: 1rem;
  max-width: 100%;
  outline: 0;
  padding: 6px 2px 6px 6px;
  position: relative;
  width: 95%;
}
.ngx-table__header-cell {
  position: relative;
}
.ngx-table__header-cell .pointer {
  cursor: pointer;
}
.ngx-table__horizontal-scroll {
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
}
.ngx-table__horizontal-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 10px;
}
.ngx-table__horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: grey;
}
.ngx-table__column-resizer {
  background-color: #e7e9ed;
  cursor: col-resize;
  display: block;
  height: 20px;
  margin-top: 10px;
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  width: 2px;
}
.ngx-table__table--borderless > tbody > tr > td,
.ngx-table__table--borderless > thead > tr > th {
  border-bottom: 0 !important;
}
.ngx-table__table--hoverable > tbody tr:hover {
  background: #00000003 !important;
}
.ngx-table__table--striped > tbody tr:nth-of-type(odd) {
  background: #00000003 !important;
}
.ngx-table__table--tiny > tbody > tr > td,
.ngx-table__table--tiny > thead > tr > th {
  border-bottom: 1px solid transparent;
  // padding: 4px 7px;
}
.ngx-table__table--normal > tbody > tr > td,
.ngx-table__table--normal > thead > tr > th {
  border-bottom: 1px solid transparent;
  // padding: 10px 6px;
}
.ngx-table__table--big > tbody > tr > td,
.ngx-table__table--big > thead > tr > th {
  border-bottom: 1px solid transparent;
  // padding: 12px 8px;
}
.ngx-table__table--dark {
  background-color: $black-color;
  color: #f6f7f9;
}
.ngx-table__table--normal > thead > tr{
  border-radius:2px 0px;
  background-color:  $grid-header-bg;
  color: $grid-header-bg !important;
}

.ngx-table__table--dark > tbody tr:hover {
  color: $black-color;
}
.ngx-table__table--dark.ngx-table__table--striped > tbody tr:nth-of-type(odd) {
  background: #5f697d;
}
.ngx-table__table--dark-pagination ::ng-deep .ngx-pagination .current {
  background: #f6f7f9;
  color: $black-color;
}
.ngx-table__table--dark-pagination ::ng-deep pagination-template > ul {
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
}
.ngx-table__table--dark-pagination ::ng-deep pagination-template > ul > li {
  border: 1px solid #e7e9ed;
  background: $black-color;
}
.ngx-table__table--dark-pagination ::ng-deep pagination-template > ul > li.current {
  border: 1px solid $black-color;
}
.ngx-table__table--dark-pagination ::ng-deep pagination-template > ul > li > a {
  color: #f6f7f9;
}
.ngx-table__table--dark-pagination-wrapper {
  background-color: $black-color;
}
.ngx-table__table--dark-pagination-range > #rowAmount > div > div {
  color: #e7e9ed;
  border: 1px solid #e7e9ed;
  background-color: $black-color;
}
.ngx-table__table--dark-pagination-range > #rowAmount > div > ul {
  color: #e7e9ed;
  background-color: $black-color;
}
.ngx-table__table--dark-pagination-range > #rowAmount > div > ul > li {
  color: #e7e9ed;
  background-color: $black-color;
}
.ngx-table__header-title {
  display: inline;
  > div{
    font-size: 7px;
    padding: 0px 3px;
    position: relative;
    top: -1px;
  }
}
.ngx-menu {
  background: #fff;
  border: 1px solid #e7e9ed;
  list-style: none;
  margin: 0;
  min-width: 60px;
  z-index: 100;
}
.ngx-form-icon {
  border-radius: 0.1rem;
  background: #fff;
  height: 0.9rem;
  left: 0;
  top: 0.2rem;
  width: 0.9rem;
  transition: all 0.2s ease;
  border: 0.05rem solid #e7e9ed;
  cursor: pointer;
  display: inline-block;
  position: absolute;
}
.ngx-form-checkbox {
  line-height: 1rem;
  margin: 0.2rem 0;
  min-height: 1.2rem;
  padding: 0.2rem 0.4rem 0.4rem 1.6rem;
  position: relative;
}
.ngx-form-checkbox input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.ngx-form-checkbox input:focus + .ngx-form-icon {
  box-shadow: 0 0 0 0.1rem rgba(63, 134, 202, 0.2);
  border-color: #3f86ca;
}
.ngx-form-checkbox input:checked + .ngx-form-icon {
  background: #3f86ca;
  border-color: #3f86ca;
}
.ngx-form-checkbox input:active + .ngx-form-icon {
  background: $black-color;
}
.ngx-form-checkbox input:checked + .ngx-form-icon::before {
  background-clip: padding-box;
  border: 0.1rem solid #fff;
  border-left-width: 0;
  border-top-width: 0;
  content: "";
  height: 12px;
  left: 50%;
  margin-left: -3px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 6px;
}
.ngx-icon {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-style: normal;
  height: 1em;
  position: relative;
  text-indent: -9999px;
  vertical-align: middle;
  width: 1em;
}
.ngx-icon::before, .ngx-icon::after {
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ngx-icon-arrow-down::before, .ngx-icon-arrow-right::before, .ngx-icon-arrow-up::before {
  border: 0.1rem solid $black-color;
  border-bottom: 0;
  border-right: 0;
  height: 0.65em;
  width: 0.65em;
}
.ngx-icon-arrow-down::before {
  transform: translate(-50%, -75%) rotate(225deg);
}
.ngx-icon-arrow-right::before {
  transform: translate(-75%, -50%) rotate(135deg);
}
.ngx-icon-arrow-up::before {
  transform: translate(-50%, -25%) rotate(45deg);
}
.ngx-icon-menu::before {
  background: $black-color;
  box-shadow: 0 -0.35em, 0 0.35em;
  content: "";
  height: 0.1rem;
  width: 100%;
}
.ngx-icon-pin {
  border-radius: 50%;
  border: 4px solid #50596c;
  width: 11px;
  height: 11px;
}
.ngx-icon-more::before {
  background: #50596c;
  border-radius: 50%;
  box-shadow: 0 -0.4em, 0 0.4em;
  height: 3px;
  width: 3px;
}
.ngx-dropdown {
  display: inline;
  position: relative;
  float: right;
}
.ngx-dropdown .ngx-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
}
.ngx-dropdown a {
  color: #50596c;
}
.ngx-btn-group {
  display: inline;
}
.ngx-draggable-row {
  cursor: move;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 1px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding-top: 9px;
  padding-left: 4px;
  display: table;
  table-layout: fixed;
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}
.ngx-draggable-row-area.cdk-drop-list-dragging .ngx-draggable-row:not(.cdk-drag-placeholder) {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}
.ngx-table__header-cell--draggable {
  cursor: move;
  background-color: white;
}
.ngx-table__header--draggable.cdk-drop-list-dragging .ngx-table__header-cell--draggable:not(.cdk-drag-placeholder) {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}
.ngx-pagination {
  font-family: Montserrat, Verdana, serif !important;
  display: flex;
  margin-left: 0;
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
}
.ngx-pagination::before,
.ngx-pagination::after {
  content: " ";
  display: table;
}
.ngx-pagination::after {
  clear: both;
}
.ngx-pagination li {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border-radius: 0;
  border: 1px solid #e7e9ed;
  padding: 3px 7.5px !important;
  color: #cacaca;
  cursor: default;
  border-radius: 2px;
  margin: 0 3px;
  min-width: 13px;
  text-align: center;
  &:before{
    margin: 0 !important;
    font-size: 20px;
  }
  &:after{
    margin: 0 !important;
    font-size: 20px;
  }
}
.ngx-pagination li.current {
  border: 2px solid #808080;
  position: relative;
}
.ngx-pagination li {
  display: inline-flex;
  align-items: center;
  width: 27px;
  height: 27px;
  border: 2px solid #e8e7e7;
  color: #323232;
  font-size: 12px;
  line-height: 16px;
}
.ngx-pagination a,
.ngx-pagination button {
  color: #50596c;
  display: block;
  border-radius: 0;
}
.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: #e6e6e6;
}
.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #fff;
  color: #323232;
  cursor: default;
}
.ngx-pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: default;
  height: 27px;
}
.ngx-pagination .disabled:hover {
  background: transparent;
}
.ngx-pagination a,
.ngx-pagination button {
  cursor: pointer;
}
.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\2039";
  display: inline-block;
  margin-right: 0rem;
  line-height: 16px;
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\203A";
  display: inline-block;
  margin-right: 0rem;
  line-height: 16px;
}
.ngx-pagination .show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.ngx-pagination .small-screen {
  display: none;
}
.ngx-pagination-wrapper {
  margin-top: 8px;
}
.ngx-pagination-range {
  display: inline-block;
  float: right;
}
.ngx-pagination-steps {
  display: inline-block;
}
.ngx-pagination-range-dropdown {
  margin-top: 0;
}
.ngx-pagination-range-dropdown-button-item {
  cursor: pointer;
  color: #50596c;
  padding: 0.4rem;
}
.ngx-pagination-range--selected {
  background-color: #f6f7f9;
}
.ngx-pagination-range-dropdown-button {
  color: #50596c;
  border: 1px solid #e7e9ed;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  .ngx-icon{
    font-size: 7px;
    padding-left: 12px;
  }
}
.pinned-left {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background-color: white;
  box-shadow: 2px 0 0 rgba(128, 128, 128, 0.175);
}
@media screen and (max-width: 601px) {
  .ngx-pagination.responsive .small-screen {
    display: inline-block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
  }

  .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
    display: none;
  }
}
.ngx-infinite-scroll-viewport {
  display: block;
  height: 250px;
  overflow: auto;
}
.ngx-infinite-scroll-viewport tr,
.ngx-infinite-scroll-viewport-thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
pagination{
  background: #ffffff;
   display: block;
  border-radius: 0 0 6px 6px;
  padding: 10px 15px;
  margin-top: 0;
}

.ngx-th {
  table {
    tr {
      th {
        &:last-child {
          width: 15% !important;
        }
      }
      td {
        .material-icons {
          color: #200e32;
        }
      }
    }
  }
}

.dsh-ngx-th {
  table {
    tr {
      th {
        &:last-child {
          width: 20% !important;
        }
      }
      td {
        .material-icons {
          color: #200e32;
        }
      }
    }
  }
}
yellow-time-web-employees-overview .ngx-table td {
  padding: 8px 15px;
}
.ngx-table__table-no-results {
  min-height: calc(100vh - 45vh);
  padding: 18px;
}

.ngx-table__header{
 border-radius: 0px 2px 0px 2px;
}

.ngx-menu {
    padding: 0;
    min-width: 46px;
}